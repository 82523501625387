::selection {
    color: var(--text);
    background: var(--accent);
}

:root {
    --6px: 0.375rem;
    --10px: 0.625rem;
    --12px: 0.75rem;
    --18px: 1.125rem;
    --24px: 1.5rem;
    --36px: 2.25rem;

    --spacing-0: 0;
    --spacing-6: var(--6px);
    --spacing-12: var(--12px);
    --spacing-18: var(--18px);
    --spacing-24: var(--24px);
    --spacing-36: var(--36px);

    --border-radius: 10px;
    --border-width: 2px;

    --extension-width: 375px;
    --extension-height: 600px;

    --ext-padding-left-right: var(--spacing-18);
    --ext-padding-top: var(--spacing-18);
    --ext-padding-bottom: var(--spacing-36);
    --ext-v-gap: var(--spacing-12);
    --ext-h-gap: var(--spacing-12);

    --duration: 150ms;
}

:root {
    --text: rgb(55, 53, 47);
    --background: #f9f9f9;
    --text-field-background: white;

    --accent: #50F17D;
    --accent-text: var(--text);
    --logo-text: black;
    --accent-shadow: #2CD498;
    --warning: #FFAB49;
    --yellow: #FFF856;
    --danger: #F1506D;
    --danger-text: white;
    --danger-shadow: #DB38CB;

    --disabled: #D8D8D8;
    --disabled-text: #656565;
    --disabled-outline: #A6A6A6;

    --border: #D7DDE2;

    --icon-outline: transparent;

    --qr-wrapper: white;
}

html[theme="system"] {
    @media (prefers-color-scheme: dark) {
        --text: white;
        --background: rgb(25, 25, 25);
        --text-field-background: var(--background);
        --logo-text: white;

        --accent: #2CD498;
        --accent-text: black;
        --accent-shadow: #20B593;
        --danger: #EB3C7B;
        --danger-text: black;
        --danger-shadow: #C81CA2;

        --disabled: #4D4D4D;
        --disabled-text: #B4B4B4;
        --disabled-outline: #727272;

        --icon-outline: white;

        --qr-wrapper: white;
    }
}

html[theme="dark"] {
    --text: white;
    --background: rgb(25, 25, 25);
    --text-field-background: var(--background);
    --logo-text: white;

    --accent: #2CD498;
    --accent-text: black;
    --accent-shadow: #20B593;
    --danger: #EB3C7B;
    --danger-text: black;
    --danger-shadow: #C81CA2;

    --disabled: #4D4D4D;
    --disabled-text: #B4B4B4;
    --disabled-outline: #727272;

    --icon-outline: white;
    --qr-wrapper: white;
}


