::selection {
  color: var(--text);
  background: var(--accent);
}

:root {
  --6px: .375rem;
  --10px: .625rem;
  --12px: .75rem;
  --18px: 1.125rem;
  --24px: 1.5rem;
  --36px: 2.25rem;
  --spacing-0: 0;
  --spacing-6: var(--6px);
  --spacing-12: var(--12px);
  --spacing-18: var(--18px);
  --spacing-24: var(--24px);
  --spacing-36: var(--36px);
  --border-radius: 10px;
  --border-width: 2px;
  --extension-width: 375px;
  --extension-height: 600px;
  --ext-padding-left-right: var(--spacing-18);
  --ext-padding-top: var(--spacing-18);
  --ext-padding-bottom: var(--spacing-36);
  --ext-v-gap: var(--spacing-12);
  --ext-h-gap: var(--spacing-12);
  --duration: .15s;
  --text: #37352f;
  --background: #f9f9f9;
  --text-field-background: white;
  --accent: #50f17d;
  --accent-text: var(--text);
  --logo-text: black;
  --accent-shadow: #2cd498;
  --warning: #ffab49;
  --yellow: #fff856;
  --danger: #f1506d;
  --danger-text: white;
  --danger-shadow: #db38cb;
  --disabled: #d8d8d8;
  --disabled-text: #656565;
  --disabled-outline: #a6a6a6;
  --border: #d7dde2;
  --icon-outline: transparent;
  --qr-wrapper: white;
}

html[theme="system"] {
  @media (prefers-color-scheme: dark) {
    & {
      --text: white;
      --background: #191919;
      --text-field-background: var(--background);
      --logo-text: white;
      --accent: #2cd498;
      --accent-text: black;
      --accent-shadow: #20b593;
      --danger: #eb3c7b;
      --danger-text: black;
      --danger-shadow: #c81ca2;
      --disabled: #4d4d4d;
      --disabled-text: #b4b4b4;
      --disabled-outline: #727272;
      --icon-outline: white;
      --qr-wrapper: white;
    }
  }
}

html[theme="dark"] {
  --text: white;
  --background: #191919;
  --text-field-background: var(--background);
  --logo-text: white;
  --accent: #2cd498;
  --accent-text: black;
  --accent-shadow: #20b593;
  --danger: #eb3c7b;
  --danger-text: black;
  --danger-shadow: #c81ca2;
  --disabled: #4d4d4d;
  --disabled-text: #b4b4b4;
  --disabled-outline: #727272;
  --icon-outline: white;
  --qr-wrapper: white;
}
/*# sourceMappingURL=index.c6b21b2a.css.map */
